import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition.js";
import { isMobile } from "react-device-detect";

function Features() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height =
        tabs.current.children[tab - 1].offsetHeight + "px";
    }
  };

  const textTruncate = (str, length) =>
    str.length > length ? `${str.substring(0, length)}...` : str;

  const tabInfo = [
    {
      id: 1,
      name: "Life Settlement Trading Solutions",
      description:
        "Our trading team has successfully delivered auctions and private placements of more than $3.5 billion in Life Settlements through our large network of institutional investors always focusing on maximizing the value of our clients’ Life Settlement holdings.",
      image: "anmani-logo.png",
      link: "https://anmaniassets.com",
    },
    {
      id: 2,
      name: "Portfolio Servicing & Asset Management Solutions",
      description:
        "Since its inception in 2002 Asset Servicing Group, LLC has been servicing thousands of Life Settlement policies with over $7.6 billion in face value, communicating with, and tracking thousands of insureds and managing thousands of investor relationships making it one of the leading Life Settlement Servicing companies in the United States of America.",
      image: "asg-logo.png",
      link: "https://asgllc.us",
    },
    {
      id: 3,
      name: "Life Settlement Escrow Services",
      description:
        "Over the last decade First Escrow has delivered uncountable escrow services to the Life Settlement industry ranging from individual life insurance policy settlements to large portfolio transactions. Our escrow services entail the entire process from the establishment of an escrow account for each client and transaction, monitoring that all transaction criteria are met, managing change forms release to insurance company and verification of ownership transfer to releasing funds to seller and other parties as directed and forwarding collateral packages to purchaser.",
      image: "first-escrow-logo.png",
    },
    {
      id: 4,
      name: "Securities Intermediary and Custodial Services",
      description:
        "In its capacity as Securities Intermediary for the benefit of the Life Settlement investors/purchasers, Heritagemark, LLC becomes the owner and beneficiary of the Life Settlement policy on the records of the insurance company which allows Heritagemark to efficiently service and manage large life insurance portfolios. ",
      image: "heritage-mark-logo.png",
    },
  ];

  const TabButton = (props) => (
    <a
      className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
        tab !== props.id
          ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
          : "bg-gray-200 border-transparent"
      }`}
      href="#0"
      onClick={(e) => {
        e.preventDefault();
        setTab(props.id);
      }}
    >
      <div>
        <div className="font-bold leading-snug tracking-tight mb-1 text-center lg:text-left">
          {props.title}
        </div>
        <div className="text-gray-600 text-sm">
          {!isMobile ? (
            textTruncate(props.description, 150)
          ) : (
            <>
              <div className="relative inline-flex flex-col">
                {props.image && (
                  <img
                    className="md:max-w-none mx-auto rounded mt-4 lg:mt-0"
                    src={require(`../images/${props.image}`)}
                    width={!isMobile ? 250 : 150}
                    alt="Features bg"
                  />
                )}

                <p className="text-left mt-6">{props.description}</p>
                {props.link ? (
                  <a
                    href={props.link}
                    className="mt-4 mx-auto btn-sm text-gray-200 lg:bg-primary bg-gray-900 hover:bg-gray-800 self-start font-bold uppercase"
                  >
                    Visit Site
                  </a>
                ) : (
                  <p className="text-left font-bold mt-4">
                    Please do not hesitate to contact us at:{" "}
                    <a href="mailto:info@asgllc.us" className="text-primary">
                      info@asgllc.us
                    </a>
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </a>
  );

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <section className="relative" id="solutions" className="mb-10">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative container mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Our Solutions</h1>
            <p className="text-xl text-gray-600">
              Based on its unrivaled industry experience, an exceptional and
              dedicated team for more than a decade and a proprietary technology
              platform the Group is providing the following State of the Art
              services guiding our clients each step through their Life
              Settlement investment:
            </p>
          </div>

          {/* Section content */}
          <div className="flex">
            {/* Content */}
            <div
              className="max-w-xl md:max-w-none mx-auto pr-4 w-full"
              data-aos="fade-right"
            >
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0">
                {tabInfo.map((item) => (
                  <TabButton
                    title={item.name}
                    description={item.description}
                    id={item.id}
                    image={item.image}
                  />
                ))}
              </div>
            </div>

            {/* Tabs items */}
            <div
              className="hidden lg:block max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
              data-aos="zoom-y-out"
              ref={tabs}
            >
              <div className="pl-10 relative flex flex-col text-center lg:text-right">
                {tabInfo.map((item) => (
                  <Transition
                    show={tab === item.id}
                    appear={true}
                    className="w-full"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterStart="opacity-0 translate-y-16"
                    enterEnd="opacity-100 translate-y-0"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveStart="opacity-100 translate-y-0"
                    leaveEnd="opacity-0 -translate-y-16"
                  >
                    <div className="relative inline-flex flex-col">
                      <img
                        className="md:max-w-none mx-auto rounded"
                        src={require(`../images/${item.image}`)}
                        width="250"
                        alt="Features bg"
                      />
                      <p className="text-left mt-6">{item.description}</p>
                      {item.link ? (
                        <a
                          href={item.link}
                          className="mt-4 mx-auto btn-sm text-gray-200 lg:bg-primary bg-gray-900 hover:bg-gray-800 self-start font-bold uppercase"
                        >
                          Visit Site
                        </a>
                      ) : (
                        <p className="text-left font-bold mt-4">
                          Please do not hesitate to contact us at:{" "}
                          <a
                            href="mailto:info@asgllc.us"
                            className="text-primary"
                          >
                            info@asgllc.us
                          </a>
                        </p>
                      )}
                    </div>
                  </Transition>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
