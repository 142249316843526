import React from "react";
import clsx from "clsx";

function Nav({ className }) {
  return (
    <ul
      className={clsx(
        className,
        "lg:flex flex-grow justify-end flex-wrap items-center w-2/3 lg:w-full"
      )}
    >
      <li>
        <a
          href="./#about"
          className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
        >
          About
        </a>
      </li>
      <li>
        <a
          href="./#solutions"
          className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
        >
          Solutions
        </a>
      </li>
      {/* <li>
        <a
          href="./#approach"
          className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
        >
          Approach
        </a>
      </li> */}
      {/* <li>
        <a
          href="./#team"
          className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
        >
          Team
        </a>
      </li> */}
      <li>
        <a
          href="#contact"
          className="btn-sm text-gray-200 lg:bg-primary bg-gray-900 hover:bg-gray-800 ml-3 font-bold uppercase"
        >
          <span>Contact Us</span>
        </a>
      </li>
    </ul>
  );
}

export default Nav;
