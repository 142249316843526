import React, { useEffect, useState } from "react";
import clsx from "clsx";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Features from "../partials/Features";
import Services from "../partials/Services";
import TeamMembers from "../partials/TeamMembers";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import MobileMenu from "../partials/MobileMenu";
import AboutUs from "../partials/AboutUs";
import Contact from "../partials/Contact";

function Home() {
  // const hubButton = document.querySelector("input.hs-button.primary.large");

  // hubButton.style.backgroundColor = "#000";

  // useEffect(() =>
  //   setTimeout(() => {
  //     const hubButton = document.querySelector("input.hs-button.primary.large");
  //     !!hubButton && (hubButton.style.backgroundColor = "#000");
  //   }, 2000)
  // );
  return (
    <>
      <MobileMenu />
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}

        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page sections */}
          <HeroHome />
          <AboutUs />
          <Cta />
          <Features />
          <Contact />
          {/* <Services /> */}
          {/* <TeamMembers /> */}
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
}

export default Home;
