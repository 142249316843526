import React from "react";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import SecondaryHero from "../partials/SecondaryHero";
import MobileMenu from "../partials/MobileMenu";

function PrivacyPolicy() {
  return (
    <>
      <MobileMenu />
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />
        <SecondaryHero />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="bg-gradient-to-b from-gray-100 to-white">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-10">
                {/* Page header */}
                <div className="max-w-3xl mx-auto pb-12 md:pb-20">
                  <h1 className="mb-6 font-bold text-xl">
                    {" "}
                    Privacy Policy for Anmani Holdings, LLC
                  </h1>
                  <p className="mb-4">
                    {" "}
                    If you require any more information or have any questions
                    about our privacy policy, please feel free to contact us by
                    email at roever@anmaniassets.com.
                  </p>
                  <p className="mb-4">
                    At Anmani Holdings, LLC, we consider the privacy of our
                    visitors to be extremely important. This privacy policy
                    document describes in detail the types of personal
                    information is collected and recorded by Anmani Assets, LLC
                    and how we use it.{" "}
                  </p>
                  <b>Log Files</b>
                  <p className="mb-4">
                    <br /> Like many other Web sites, anmaniholdings.com makes
                    use of log files. These files merely logs visitors to the
                    site – usually a standard procedure for hosting companies
                    and a part of hosting services's analytics. The information
                    inside the log files includes internet protocol (IP)
                    addresses, browser type, Internet Service Provider (ISP),
                    date/time stamp, referring/exit pages, and possibly the
                    number of clicks. This information is used to analyze
                    trends, administer the site, track user's movement around
                    the site, and gather demographic information. IP addresses,
                    and other such information are not linked to any information
                    that is personally identifiable.{" "}
                  </p>
                  <b>Cookies and Web Beacons</b>
                  <p className="mb-4">
                    <br />
                    anmaniholdings.com uses cookies to store information about
                    visitors' preferences, to record user-specific information
                    on which pages the site visitor accesses or visits, and to
                    personalize or customize our web page content based upon
                    visitors' browser type or other information that the visitor
                    sends via their browser.{" "}
                  </p>
                  <b>Third Party Privacy Policies</b>
                  <p className="mb-4">
                    <br />
                    You should consult the respective privacy policies of these
                    third-party ad servers for more detailed information on
                    their practices as well as for instructions about how to
                    opt-out of certain practices. anmaniholdings.com's privacy
                    policy does not apply to, and we cannot control the
                    activities of, such other advertisers or web sites.
                  </p>
                  <p className="mb-4">
                    If you wish to disable cookies, you may do so through your
                    individual browser options. More detailed information about
                    cookie management with specific web browsers can be found at
                    the browsers' respective websites.{" "}
                    <a href="https://www.privacypolicyonline.com/what-are-cookies/">
                      What Are Cookies?
                    </a>
                  </p>
                  <b>Online Privacy Policy Only</b>
                  <p className="mb-4">
                    <br />
                    This privacy policy applies only to our online activities
                    and is valid for visitors to our website and regarding
                    information shared and/or collected there.
                    <br />
                    This policy does not apply to any information collected
                    offline or via channels other than this website.
                  </p>
                  <b>Consent</b>
                  <p className="mb-4">
                    <br />
                    By using our website, you hereby consent to our privacy
                    policy and agree to its terms.
                  </p>
                  <b>Update</b>
                  <p className="mb-4">
                    <br />
                    This Privacy Policy was last updated on: Feb 28th, 2021.
                    Should we update, amend or make any changes to our privacy
                    policy, those changes will be posted here.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
