import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu";
import clsx from "clsx";
import { Link } from "react-router-dom";

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

// create a button that calls a context function to set a new open state when clicked
const Button = () => {
  const ctx = useContext(MyContext);
  return (
    <div className="">
      <img
        src={require("../images/anmani-holdings-logo.png")}
        alt="Logo"
        className="py-2 px-4 h-auto w-1/2 inline-block"
      />
      <div
        className="w-1/2 text-right pt-4 pr-4 float-right inline-block"
        onClick={ctx.toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} className="text-2xl" />
      </div>
    </div>
  );
};

// create a navigation component that wraps the burger menu
const Navigation = () => {
  const ctx = useContext(MyContext);

  return (
    <Menu
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      menuClassName="bg-primary"
      right={true}
      customBurgerIcon={false}
      customCrossIcon={
        <FontAwesomeIcon
          icon={faTimes}
          className="text-2xl text-white"
          onClick={ctx.toggleMenu}
        />
      }
      noOverlay={true}
      styles={{
        bmBurgerButton: {
          zIndex: "1000",
        },
        bmCrossButton: {
          height: ".75rem",
          right: "12px",
          top: "14px",
          width: ".75rem",
        },
      }}
      width="100%"
    >
      <Link to="/" className="block" aria-label="Anmani Holdings">
        <img
          src={require("../images/anmani-holdings-logo-white.png")}
          alt="Logo"
          className="py-2 px-4 w-3/4"
        />
      </Link>
      <ul
        className={clsx(
          "lg:flex flex-grow justify-end flex-wrap items-center w-2/3 lg:w-full text-white"
        )}
      >
        <li>
          <a
            onClick={ctx.toggleMenu}
            href="./#about"
            className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
          >
            About
          </a>
        </li>
        <li>
          <a
            onClick={ctx.toggleMenu}
            href="./#solutions"
            className="font-medium hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
          >
            Solutions
          </a>
        </li>
        <li>
          <a
            onClick={ctx.toggleMenu}
            href="#contact"
            className="btn-sm text-gray-200 lg:bg-primary bg-gray-900 hover:bg-gray-800 ml-3 font-bold uppercase"
          >
            <span>Contact Us</span>
          </a>
        </li>
      </ul>
    </Menu>
  );
};

// default export here
const MobileMenu = () => {
  return (
    <MyProvider>
      <div className="mb-4 lg:hidden md:hidden fixed top-0 z-50 bg-white blur shadow-lg bg-opacity-90 w-full">
        <Button />
      </div>
      <div>
        <Navigation />
      </div>
    </MyProvider>
  );
};

export default MobileMenu;
