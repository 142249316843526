import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import Nav from "./Nav";

function Header() {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const textColorChange = top || isMobile;

  return (
    <header
      className={`hidden lg:block lg:fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white blur shadow-lg"
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Anmani Holdings">
              <img
                src={
                  top
                    ? require("../images/anmani-holdings-logo-white.png")
                    : require("../images/anmani-holdings-logo.png")
                }
                alt="Anmani Logo Mark"
                className={clsx({ "p-8": isMobile })}
                style={{ maxWidth: 250 }}
              />
            </Link>
          </div>
          <nav className="lg:flex flex-grow hidden">
            <Nav className={textColorChange && "text-white"} />
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
