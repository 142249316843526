import React from "react";

function HeroHome() {
  return (
    <section
      className="relative z-20"
      style={{
        backgroundImage: `url(${require("../images/hero-background.jpg")})`,
        backgroundPosition: "35% 65%",
        backgroundSize: "cover",
      }}
    >
      <div
        className="h-full w-full"
        style={{ background: "rgba(0, 16, 24, .50)" }}
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Hero content */}
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Section header */}
            <div className="text-center pb-12 md:pb-16 flex items-center justify-center">
              <span
                className="mr-4 text-5xl md:text-6xl leading-tighter tracking-tighter mb-4 text-white"
                data-aos="zoom-y-out"
              >
                <img src={require("../images/ah-logo-mark.png")} />
              </span>
              <div className="max-w-3xl">
                <p
                  className="text-2xl text-white mt-10 mb-8"
                  data-aos="zoom-y-out"
                  data-aos-delay="300"
                >
                  Providing Life Settlement solutions for every step in your
                  journey.
                </p>
                <div
                  className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                  data-aos="zoom-y-out"
                  data-aos-delay="400"
                >
                  <div>
                    <a
                      className="uppercase font-bold btn text-white bg-primary hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                      href="#solutions"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Hero image */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
