import React from "react";

function Cta() {
  return (
    <section
      className="relative"
      style={{
        backgroundImage: `url(${require("../images/cta-background.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "65% 35%",
      }}
    >
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="z-50 absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>
      <div
        className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden"
        data-aos="zoom-y-out"
        style={{ background: "rgba(0, 16, 24, .50)" }}
      >
        <div className="container mx-auto px-4 sm:px-6">
          <div className="pb-6">
            {/* CTA box */}

            <div className="relative flex flex-col w-3/4 mx-auto py-6 justify-between items-center">
              {/* CTA content */}
              <div className="text-center">
                <h3 className="h3 mb-4 text-white">
                  Find out how a partnership with us can benefit your portfolio.
                </h3>
              </div>
              <a
                href="#contact"
                className="font-bold uppercase btn-sm text-gray-200 lg:bg-primary bg-gray-900 hover:bg-gray-800 ml-3"
              >
                <span className="block text-center">Schedule Consultation</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
