import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Form from "./Form";

function Footer() {
  return (
    <footer id="footer">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-6">
            <div className="mb-2">
              {/* Logo */}
              <Link
                to="/"
                className="inline-block mb-4"
                aria-label="Anmani Holdings"
              >
                <img
                  src={require("../images/anmani-holdings-logo.png")}
                  alt="Anmani Logo"
                />
              </Link>

              <p>
                Anmani Holdings, LLC is an active member, through its subsidiaries, in the two most
                prominent trade organizations “LISA – Life Insurance Settlement
                Association” (USA) and “ELSA – European Life Settlement
                Association” (Europe).
              </p>
              <hr className="my-8" />
              <div className="md:col-span-3 lg:col-span-3 mt-6 flex lg:w-1/2">
                <a href="https://www.lisa.org/">
                  <img
                    src={require("../images/lisa-logo.webp")}
                    alt="LISA Logo"
                    className="pr-6"
                  />
                </a>
                <a href="https://www.elsa-sls.org/" className="block">
                  <img
                    src={require("../images/elsa-logo.png")}
                    alt="Elsa Logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6 lg:col-span-3"></div>

          {/* 2nd block */}
          <div className="sm:col-span-12 md:col-span-6 lg:col-span-3 lg:mr-10">
            <h6 className="text-gray-800 font-medium mb-2">
              Oklahoma City Office
            </h6>
            <p className="text-gray-600 transition duration-150 ease-in-out">
              521 W. Wilshire Blvd.
            </p>
            <p className="text-gray-600 transition duration-150 ease-in-out">
              Oklahoma City, OK 73116
            </p>
            <a
              className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              href="tel:405-753-9100"
            >
              405.753.9100
            </a>
          </div>
        </div>
        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">
            All rights reserved. Copyright &copy; {format(new Date(), "yyyy")}
          </div>
          <div className="text-sm text-gray-600">
            <Link
              to="privacy-policy"
              className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
