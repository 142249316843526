import React from "react";

function SecondaryHero() {
  return (
    <section
      className="relative z-20"
      style={{
        backgroundImage: `url(${require("../images/hero-background.jpg")})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="h-full w-full"
        style={{ background: "rgba(0, 16, 24, .50)" }}
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Hero content */}
          <div className="pt-10 pb-6 md:pt-40 md:pb-6">
            {/* Section header */}
            <div className="pb-4 md:pb-6">
              <h1
                className="text-3xl md:text-4xl  leading-tighter tracking-tighter mb-4 text-white mt-10"
                data-aos="zoom-y-out"
              >
                Privacy Policy
              </h1>
            </div>

            {/* Hero image */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondaryHero;
