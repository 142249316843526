import React from "react";
import Form from "./Form";
import { Heading } from "./Heading";

function Contact() {
  return (
    <section
      className="relative"
      style={{
        backgroundImage: `url(${require("../images/hero-background.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "35% 65%",
        backgroundAttachment: 'fixed',
        minHeight: 500,
      }}

      id="contact"
    >
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
            style={{ background: "rgba(0, 16, 24, .50)" }}
        className="relative rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden"
        data-aos="zoom-y-out"
      >
        <div className="container mx-auto px-4 sm:px-6 text-center">
          <div className="flex justify-center">
            <div className="mt-4  lg:w-3/4 bg-white p-8 rounded">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
