import React from "react";
import { Heading } from "./Heading";

function AboutUs() {
  return (
    <section className="relative" id="about">
      <div className="max-w-6xl mx-auto pb-12 md:pb-20 px-8 lg:px-0">
        <div className="pt-12 md:pt-20 text-center">
          <Heading title="About Us" className="mb-4" />
          <p className="text-xl text-gray-600">
            Anmani Holdings provides a fully-integrated Life Settlement Platform
            which encompasses the entire infrastructure and services needed for
            the successful management of your Life Settlement investments in a
            most efficient, effective, and professional manner.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
