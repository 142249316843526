import React, { useEffect } from "react";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

function Form() {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "14498821",
    formId: "2535a1a3-1e54-4754-a25f-79920709f8f6",
    target: "#my-hubspot-form",
  });

  return (
    <>
      <div id="my-hubspot-form" className="text-white"></div>
    </>
  );
}

export default Form;